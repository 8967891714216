<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p>
        Theoretically, what volume of hydrogen gas (in mL) at
        <number-value :value="temp" unit="^\circ\text{C}" />
        and
        <number-value :value="pressure" unit="\text{torr}" />
        is produced in the reaction of
        <number-value :value="massMg" unit="\text{g Mg}" />
        metal with excess hydrochloric acid to produce
        <chemical-latex content="MgCl2" />
        and
        <chemical-latex content="H2\text{.}" />
        <b>Hint.</b> You may want to write the balanced equation first.
      </p>

      <calculation-input
        v-model="inputs.input1"
        prepend-text="$\text{Volume}:$"
        append-text="$\text{mL}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemOleMiss116Post2Q2part2',
  components: {
    ChemicalLatex,
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
    };
  },
  computed: {
    temp() {
      return this.taskState.getValueBySymbol('temp').content;
    },
    pressure() {
      return this.taskState.getValueBySymbol('pressure').content;
    },
    massMg() {
      return this.taskState.getValueBySymbol('massMg').content;
    },
  },
};
</script>
